define("discourse/plugins/discourse-menu-icon/discourse/initializers/menu-icon", ["exports", "discourse/widgets/widget", "virtual-dom"], function (_exports, _widget, _virtualDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'menu-icon',
    initialize() {
      if (Discourse.SiteSettings.menu_icon_enabled) {
        (0, _widget.decorateWidget)('header-icons:before', helper => {
          const showExtraInfo = helper.attrs.minimized;
          if (!showExtraInfo) {
            return (0, _virtualDom.h)('li.header-dropdown-toggle', [(0, _virtualDom.h)('a.icon.btn-flat', {
              'id': Discourse.SiteSettings.menu_icon_id,
              'href': Discourse.SiteSettings.menu_icon_url,
              'title': Discourse.SiteSettings.menu_icon_title,
              'attributes': {
                'aria-label': Discourse.SiteSettings.menu_icon_title,
                'target': Discourse.SiteSettings.menu_icon_new_window ? "_blank" : "_self"
              }
            }, [(0, _virtualDom.h)('i.fa.' + Discourse.SiteSettings.menu_icon_icon + '.d-icon', {
              'aria-hidden': 'true'
            })])]);
          }
        });
      }
    }
  };
});